import React, { useEffect, useState } from "react";

import Task from "../components/EarnPage/Task";
import SpecialTaskPopup from "../components/EarnPage/SpecialTaskPopup";
import WalletTask from "../components/EarnPage/WalletTask";
import InvitesTask from "../components/EarnPage/InvitesTask";
import TransactionTask from "../components/EarnPage/TransactionTask";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function EarnPage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    const [ activeMarkerPosition, setActiveMarkerPosition ] = useState("left");

    const [ specialTasks, setSpecialTasks ] = useState([]);
    const [ partnerTasks, setPartnerTasks ] = useState([]);
    const [ inGameTasks, setInGameTasks ] = useState([]);
    const [ staticTasksStatuses, setStaticTasksStatuses ] = useState({});
    const [ friends, setFriends ] = useState([]);

    const [ gameAvailability, setGameAvailability ] = useState({});

    useEffect(() => {
        setPartnerTasks(JSON.parse(localStorage.getItem("partnerTasks")));
        setInGameTasks(JSON.parse(localStorage.getItem("inGameTasks")));
        setSpecialTasks(JSON.parse(localStorage.getItem("specialTasks")));
        setGameAvailability(JSON.parse(localStorage.getItem("gameAvailability")));
        setStaticTasksStatuses(JSON.parse(localStorage.getItem("staticTasksStatuses")));
        setFriends(JSON.parse(localStorage.getItem("friends")));

        socket.on("tasks", (data) => {
            setPartnerTasks(data.partnerTasks);
            setInGameTasks(data.inGameTasks);
        });

        socket.on("specialTasks", (data) => {
            setSpecialTasks(data.specialTasks);
        });

        socket.on("staticTasksStatuses", (data) => {
            setStaticTasksStatuses(data);
            socket.emit("getPoints");
        });

        socket.on("gameAvailability", (data) => {
            setGameAvailability(data);
        });
    }, []);


    // Set active section
    const setActive = (activePosition) => {
        setActiveMarkerPosition(activePosition);
    };

    // Popup handlers and states
    const [ isPopupVisible, setIsPopupVisible ] = useState(false); 
    const [ popupTaskId, setPopupTaskId ] = useState(null);
    const [ popupHeader, setPopupHeader ] = useState("");
    const [ popupSubHeader, setPopupSubHeader ] = useState("");
    const [ popupSubTasks, setPopupSubTasks ] = useState([]);

    useEffect(() => {
        window.Telegram.WebApp.BackButton.hide();
    }, []);

    // Handle special tasks list update
    // Or update of the task for popup
    useEffect(() => {
        if(!popupTaskId) return;

        const specialTask = specialTasks.find(task => task._id==popupTaskId);

        setPopupSubTasks(specialTask.subTasks);
        setPopupHeader(specialTask.header);
        setPopupSubHeader(specialTask.subHeader);
    }, [popupTaskId, specialTasks]);

    const hidePopup = () => {
        document.body.style.overflow = "scroll";
        setIsPopupVisible(false)

        setPopupTaskId(null);
    };

    const showPopup = (taskId) => {
        document.body.style.overflow = "hidden";
        setIsPopupVisible(true);

        setPopupTaskId(taskId);
    };

    return (
        <div id="earn-page">
            <div className="fade-in-eclipse"></div>
            <div 
                style={{ opacity: isPopupVisible ? "40%" : "0%" }}
                className="eclipse"
            ></div>

            <div onClick={gameAvailability.isAvailable ? () => setPage("game") : () => {}} id="game-preview"  style={{backgroundImage: `url("${localStorage.getItem("gamePreview")}")`}}>
                <div className={"play-button " + (gameAvailability.isAvailable ? "" : "disabled") }>Play</div>
            </div>

            <div id="tasks-container">
                <div id="tasks-navigation">
                    <div className={activeMarkerPosition==="left" ? "active" : ""} onClick={() => setActive("left")}>Special</div>
                    <div className={activeMarkerPosition==="center" ? "active" : ""} onClick={() => setActive("center")}>In-game</div>
                    <div className={activeMarkerPosition==="right" ? "active" : ""} onClick={() => setActive("right")}>Partner</div>
                    <div className={"active-marker "+activeMarkerPosition}></div>
                </div>

                <div className="tasks">
                    {
                        activeMarkerPosition==="left" &&
                        specialTasks.map((task, index) => 
                            <Task
                                key={task._id}
                                id={task._id}
                                name={task.name}
                                reward={task.reward}
                                status={task.status}
                                remainingTime={task.remainingTime}
                                isSpecial={true}
                                image={task.image}
                                showPopup={showPopup}
                            />
                        )
                    }

                    {
                        activeMarkerPosition==="right" &&
                        partnerTasks.map((task, index) => 
                            <Task
                                key={task._id}
                                name={task.name}
                                reward={task.reward}
                                status={task.status}
                                image={task.image}
                                link={task.link}
                                id={task._id}
                                remainingTime={task.remainingTime}
                            />
                        )
                    }

                    {
                        activeMarkerPosition==="center" &&
                        <>
                            {
                                inGameTasks.map((task, index) => 
                                    <Task
                                        key={task._id}
                                        name={task.name}
                                        reward={task.reward}
                                        status={task.status}
                                        image={task.image}
                                        link={task.link}
                                        id={task._id}
                                        remainingTime={task.remainingTime}
                                    />
                                )
                            }
                            <WalletTask
                                name={"Connect TON Wallet"}
                                reward={2000}
                                status={staticTasksStatuses.wallet}
                            />
                            <TransactionTask 
                                name={"Make 0.2 TON transaction"}
                                reward={20000}
                                status={staticTasksStatuses.transaction}
                            />
                            <InvitesTask
                                number={1}
                                name={"Invite 1 friend"}
                                reward={100}
                                progress={friends.length}
                                total={1}
                            />
                            <InvitesTask
                                number={2}
                                name={"Invite 5 friends"}
                                reward={500}
                                progress={friends.length}
                                total={5}
                            />
                            <InvitesTask
                                number={3}
                                name={"Invite 10 friends"}
                                reward={1000}
                                progress={friends.length}
                                total={10}
                            />
                        </>
                        
                    }
                </div>
            </div>

            <SpecialTaskPopup header={popupHeader} subHeader={popupSubHeader} subTasks={popupSubTasks} isVisible={isPopupVisible} hidePopup={hidePopup} />
        </div>
    );
};

export default EarnPage;