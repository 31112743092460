import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { SocketProvider } from "./contexts/SocketContext";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SocketProvider>
      <TonConnectUIProvider manifestUrl='https://mousehouses.com/images/tonconnect-manifest.json'>
        <App />
      </TonConnectUIProvider>
    </SocketProvider>
  </React.StrictMode>
);
