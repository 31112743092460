const images = require.context("../../public/images/profiles");
const imageList = images.keys().map(image => images(image));

function choose(choices) {
    let index = Math.floor(Math.random() * choices.length);
    return choices[index];
}

function getRandomProfileImage() {
    return choose(imageList);
};

export default getRandomProfileImage;