import React, { useEffect, useState } from "react";

import MouseVector from "../../vectors/Mouse";
import MultipleMiceVector from "../../vectors/MultipleMice";

import { useSocket } from "../../contexts/SocketContext";

function Balance() {
    const socket = useSocket();

    const [ points, setPoints ] = useState(0);
    const [ rewardsIncome, setRewardsIncome ] = useState(0);
    const [ tasksIncome, setTasksIncome ] = useState(0);
    const [ friendsIncome, setFriendsIncome ] = useState(0);

    useEffect(() => {
        const pointsData = JSON.parse(localStorage.getItem("pointsData"));
        setPoints(pointsData.points);
        setRewardsIncome(pointsData.rewardsIncome);
        setTasksIncome(pointsData.tasksIncome);
        setFriendsIncome(pointsData.friendsIncome);

        socket.on("points", (data) => {
            setPoints(data.points);
            setRewardsIncome(data.rewardsIncome);
            setTasksIncome(data.tasksIncome);
            setFriendsIncome(data.friendsIncome);
        });
    }, []);

    return (
        <div id="balance-container">
            {MouseVector}
            <h1>{Math.round(points)} MOUSE</h1>

            <div className="income-sources">
                <div className="source">
                    <h5>Rewards</h5>
                    <h4>
                        +{
                            rewardsIncome<1000 ? Math.round(rewardsIncome) :
                            `${Math.round((rewardsIncome/1000))}k`
                        }
                        {MultipleMiceVector}
                    </h4>
                </div>
                <div className="source">
                    <h5>Tasks</h5>
                    <h4>
                        +{
                            tasksIncome<1000 ? Math.round(tasksIncome) :
                            `${Math.round(tasksIncome/1000)}k`
                        }
                        {MultipleMiceVector}
                    </h4>
                </div>
                <div className="source">
                    <h5>Invites</h5>
                    <h4>
                        +{
                            friendsIncome<1000 ? Math.round(friendsIncome) :
                            `${Math.round(friendsIncome/1000)}k`
                        }
                        {MultipleMiceVector}
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default Balance;