import React, { useEffect, useState } from "react";

import { usePageContext } from "../contexts/PageContext";

function Stories({ pages, returnPage }) {
    const { setPage } = usePageContext();

    // Story bar states
    const [ section, setSection ] = useState(1);
    const [ sectionProgress, setSectionProgress ] = useState(0)
    const [ pause, setPause ] = useState(false);

    useEffect(() => {
        // Handlers to stop and release story-like view
        document.body.addEventListener("mousedown", () => setPause(true));
        document.body.addEventListener("mouseup", () => setPause(false));
        document.body.addEventListener("touchstart", () => setPause(true));
        document.body.addEventListener("touchend", () => setPause(false));
    }, []);

    useEffect(() => {
        // Intreval for story bars
        const interval = setInterval(() => {
            if(pause) return;
            
            if(sectionProgress === 100) {
                if(section===pages.length) {
                    return setPage(returnPage);
                };
                setSection(prev => prev+1);

                return setSectionProgress(0);
            };

            return setSectionProgress(sectionProgress+1);
        }, 50);

        return () => clearInterval(interval);
    }, [section, sectionProgress, pause]);

    function continueHandler() {
        if(section===pages.length) {
            return setPage(returnPage);
        };

        setSection(prev => prev+1); 
        setSectionProgress(0)
    };

    return (
        <div className="stories">
            <div className="story-bars">
                { 
                    pages.map((page, index) => {
                        return (
                            <div
                                className="story-bar"
                                style={{ width: (window.innerWidth-(pages.length>1 ? 60 : 0))/pages.length }}
                            >
                                <div 
                                    style={{width: section===index+1 ? `${sectionProgress}%` : section>index+1 ? "100%" : "0%"}}
                                />
                            </div>
                        );
                    })
                }
            </div>
                
            {pages[section-1]}

            <button
                className="continue-button"
                onClick={continueHandler}
            >Continue</button>
        </div>
    );
};

export default Stories;