import React from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";

import TickVector from "../../vectors/Tick";
import TonVector from "../../vectors/Ton";

import { useSocket } from "../../contexts/SocketContext";

function TransactionTask({ name, reward, status }) {
    const socket = useSocket();

    const [tonConnectUI, setOptions] = useTonConnectUI();

    async function sendTransaction() {
        const transaction = {
            validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
            messages: [
              {
                address:
                  "UQD9pChBPEYy-2KeXOnl7rlnDfOEiSb1UYdjRy_c6g78wQda", // message destination in user-friendly format
                amount: "200000000", // Toncoin in nanotons
              },
            ],
        };
        
        tonConnectUI.sendTransaction(transaction)
        .then(() => socket.emit("claimStaticTask", { type: "transaction" }))
        .catch(() => {});
    };

    return (
        <div className="task wallet-task">

            <div className="info">
                <h2>{name}</h2>
                <h3>+{reward} MOUSE</h3>
            </div>

            <div>
                <button
                    id="ton-connect"
                    onClick={ status==="active" ? sendTransaction : () => {} }
                    className={ status==="active" ? "start" : status==="check" ? "check" : "done" }
                >
                    { status==="active" ? "Send" : status==="check" ? "Check" : TonVector }
                </button>
            </div>
        </div>
    );
};

export default TransactionTask;