import React, { useEffect, useState } from "react";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function LoadingPage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    const [ loaded, setLoaded ] = useState({
        tasks: false,
        staticTasksStatuses: false,
        specialTasks: false,
        friends: false,
        pointsData: false,
        leadersData: false,
        gamePreview: false,
        gameAvailability: false
    });

    useEffect(() => {
        // Initial data loading
        socket.emit("getTasks");
        socket.emit("getSpecialTasks");
        socket.emit("getStaticTasksStatuses");
        socket.emit("getFriends");
        socket.emit("getPoints");
        socket.emit("getLeaders");
        socket.emit("isGameAvailable");

        socket.on("tasks", (data) => {
            localStorage.setItem("partnerTasks", JSON.stringify(data.partnerTasks));
            localStorage.setItem("inGameTasks", JSON.stringify(data.inGameTasks));

            setLoaded(prev => { prev["tasks"] = true; return {...prev} })
        });

        socket.on("specialTasks", (data) => {
            localStorage.setItem("specialTasks", JSON.stringify(data.specialTasks));

            setLoaded(prev => { prev["specialTasks"] = true; return {...prev} })
        });

        socket.on("staticTasksStatuses", (data) => {
            localStorage.setItem("staticTasksStatuses", JSON.stringify(data));

            setLoaded(prev => { prev["staticTasksStatuses"] = true; return {...prev} });
        });

        socket.on("friends", (data) => {
            localStorage.setItem("friends", JSON.stringify(data.friends));

            setLoaded(prev => { prev["friends"] = true; return {...prev} })
        });

        socket.on("points", (data) => {
            localStorage.setItem("pointsData", JSON.stringify(data));

            setLoaded(prev => { prev["pointsData"] = true; return {...prev} })
        });

        socket.on("leaders", (data) => {
            localStorage.setItem("leadersData", JSON.stringify(data));

            setLoaded(prev => { prev["leadersData"] = true; return {...prev} })
        });

        socket.on("gameAvailability", (data) => {
            localStorage.setItem("gameAvailability", JSON.stringify(data));

            setLoaded(prev => { prev["gameAvailability"] = true; return {...prev} })
        });

        fetchImageAsBase64(`${process.env.PUBLIC_URL}/images/game-preview.png`)
        .then(base64 => {
            localStorage.setItem("gamePreview", base64);
            setLoaded(prev => { prev["gamePreview"] = true; return {...prev} })
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if(Object.values(loaded).every(state => state)) setPage("home");
        }, 3300);

        return () => clearInterval(interval);
    }, [loaded]);

    return (
        <div id="loading-page">
            <video autoPlay loop muted playsInline>
                <source src="/loading.mp4" type="video/mp4" />
            </video>
        </div>
    );
};

const fetchImageAsBase64 = async (path) => {
    const response = await fetch(path);
    const arrayBuffer = await response.arrayBuffer();

    const base64String = `data:image/png;base64,${arrayBufferToBase64(arrayBuffer)}`;

    return base64String;
};

// Chat GPT generated
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};

export default LoadingPage;