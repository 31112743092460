import React, { useEffect, useState } from "react";

function Check({ heading, progress }) {
    const [ checked, setChecked ] = useState(false);

    useEffect(() => {
        if(progress===100) {
            setTimeout(() => setChecked(true), 1300);
        };
    }, [progress]);

    return (
        <div className='check'>
            <div className='flex-row'>
                <h2>{heading}</h2>
                
                <svg opacity={checked ? "100%" : "50%"} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="9.25" stroke="#007CFF" strokeWidth="1.5"/>
                <path d="M13 8.5L9.70711 11.7929C9.31658 12.1834 8.68342 12.1834 8.29289 11.7929L7 10.5" stroke="#007CFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className='progress-bar'>
                <div className='snake' style={{width: `${progress}%`}}></div>
            </div>
        </div>
    );
};

export default Check;