import React from "react";

function Friend({ username, reward, img }) {
    return (
        <div className="friend">
            <img src={img} alt="profile image" />

            <h4>{username}</h4>

            <h5>+<b>{Math.round(reward)}</b> MOUSE</h5>
        </div>
    );
};

export default Friend;