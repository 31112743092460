import React, { useEffect, useRef } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";

import TickVector from "../../vectors/Tick";
import TonVector from "../../vectors/Ton";

import { useSocket } from "../../contexts/SocketContext";

function WalletTask({ name, reward, status }) {
    const socket = useSocket();
    const [tonConnectUI, setOptions] = useTonConnectUI();

    async function connectWallet() {
        if(!tonConnectUI.connected) await tonConnectUI.openModal();
    
        socket.emit("startStaticTask", { type: "wallet" });
    };

    function checkTask() {
        socket.emit("claimStaticTask", { type: "wallet", connected: tonConnectUI.connected });
    };

    useEffect(() => {
        // sendTransaction()
    }, []);

    return (
        <div className="task wallet-task">
            <div className="info">
                <h2>{name}</h2>
                <h3>+{reward} MOUSE</h3>
            </div>

            <div>
                <button
                    id="ton-connect"
                    onClick={ status==="active" ? connectWallet : status==="check" ? checkTask : () => {} }
                    className={ status==="active" ? "start" : status==="check" ? "check" : "done" }
                >
                    { status==="active" ? "Connect" : status==="check" ? "Check" : TonVector }
                </button>
            </div>
        </div>
    );
};

export default WalletTask;