import React from "react";

import VerifyTickVector from "../../vectors/VerifyTick";

function Me({ img, position, username, points, tick }) {
    return (
        <div className="user me">
            <div className="image-container">
                <img src={img} alt="" />
            </div>

            <div className="info">
                <div className="flex-row">
                    <h3>{username}</h3>
                    {tick && VerifyTickVector}
                </div>
                <h4>{Math.round(points)} MOUSE</h4>
            </div>

            { position>3 && <h3>#{position}</h3> }

            { 
                position<=3 &&
                <img src={`./images/${
                    position===1 ? "gold-medal" :
                        position===2 ? "silver-medal" : "bronze-medal"
                }.png`} alt="" />
            }
        </div>
    );
};

export default Me;