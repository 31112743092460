import React, { useEffect, useRef, useState } from "react";

import SubTask from "./SubTask";

function SpecialTaskPopup({ header, subHeader, subTasks, isVisible, hidePopup }) {

    return (
        <div className={"special-task-popup " + (isVisible ? "opened" : "closed")}>
            <div className="close-line" onClick={hidePopup} ></div>

            <h1>{header}</h1>
            <h3>{subHeader}</h3>

            <div className="tasks">
                {
                    subTasks.map(subTask => 
                        <SubTask 
                            key={subTask._id}
                            id={subTask._id}
                            name={subTask.name}
                            reward={subTask.reward}
                            status={subTask.status}
                            link={subTask.link}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default SpecialTaskPopup;