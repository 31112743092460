import React, { useEffect, useState } from "react";

import Years from "../components/CongratsPage/Years";
import Points from "../components/CongratsPage/Points";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";
import Stories from "../components/Stories";

function CongratsPage() {
    const socket = useSocket();

    // Data states
    const [ years, setYears ] = useState(0);
    const [ points, setPoints ] = useState(0);

    useEffect(() => {
        socket.emit("getUser");

        socket.on("user", (data) => {
            setYears(data.years);
            setPoints(data.user.points);
        });

        document.body.style.backgroundImage = 'url("./images/bubbles-bg.svg")';

        return () => {
            document.body.style.backgroundImage = 'none';
        };
    }, []);

    return (
        <div
            id="congrats-page"
        >
            <div className="fade-in-eclipse"></div>

            <Stories pages={[ <Years years={years} />, <Points points={points} /> ]} returnPage={"home"} />

            <h4>You’re in the Top { years > 8 ? 5 : (years < 5 ? 25 : 10)  }% Telegram users 🔥</h4>
        </div>
    );
};

export default CongratsPage;