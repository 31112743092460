import React, { useRef, useState } from "react";

import MouseVector from "../../vectors/Mouse";

import { useSocket } from "../../contexts/SocketContext";

import { formatTimeDays } from "../../utils/time";

function Task({ name, reward, link, image, status, id, remainingTime=null, isSpecial=false, showPopup=() => {} }) {
    const socket = useSocket();

    const buttonRef = useRef(null);

    function startTask() {
        socket.emit("startTask", { id: id });
        
        window.open(link, '_blank').focus();
    };

    function checkTask() {

        buttonRef.current.innerText = "";
        buttonRef.current.insertAdjacentHTML("beforeend", "<div class='loading-dots'><span>•</span><span>•</span><span>•</span></div>");
        socket.emit("checkTask", { id: id });
    };

    return (
        <div className="task">
            <div
                className="image-container"
                style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/images/${image}")` }}
            >
            </div>

            <div className="info">
                <h2>{name}</h2>
                <h3>+ {reward} MOUSE</h3>
            </div>

            <div>
                <button
                    ref={buttonRef}
                    onClick={ isSpecial ? (status==="done" ? () => {} : () => showPopup(id))  :  (status==="active" ? startTask : status==="check" ? checkTask : () => {}) }
                    className={ status==="active" ? "start" : status==="check" ? "check" : "done" }
                >
                    { status==="active" ? "Start" : status==="check" ? "Check" : MouseVector }
                </button>
                {
                    remainingTime &&
                    <div className="task-timer">{`${formatTimeDays(remainingTime).days}D ${formatTimeDays(remainingTime).hours+1}H`}</div>
                }
            </div>
        </div>
    );
};

export default Task;