import React from "react";

import HomeVector from "../vectors/Home";
import ColumnsVector from "../vectors/Columns";
import FriendVector from "../vectors/Friend";
import EarnVector from "../vectors/Earn";

import { usePageContext } from "../contexts/PageContext";

function Menu() {
    const { page, setPage } = usePageContext();

    return (
        <div id="menu">
            <div onClick={() => setPage("home")} className={page==="home" ? "active" : ""}>
                {HomeVector}
                <h6>Home</h6>
            </div>
            <div onClick={() => setPage("leaderboard")} className={page==="leaderboard" ? "active" : ""}>
                {ColumnsVector}
                <h6>Leaderboard</h6>
            </div>
            <div onClick={() => setPage("friends")} className={page==="friends" ? "active" : ""}>
                {FriendVector}
                <h6>Friends</h6>
            </div>
            <div onClick={() => setPage("earn")} className={page==="earn" ? "active" : ""}>
                {EarnVector}
                <h6>Earn</h6>
            </div>
        </div>
    );
};

export default Menu;