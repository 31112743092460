import React, { useEffect, useState } from "react";

import Check from "../components/CheckPage/Check";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function CheckPage() {
    const { setPage } = usePageContext();
    const socket = useSocket();

    const [ progresses, setProgresses ] = useState([0, 0, 0, 0])

    const [ isButtonDisabled, setIsButtonDisabled ] = useState(true);

    useEffect(() => {
        for(let i = 0; i < progresses.length; i++) {
            setTimeout(() => {
                setProgresses(prev => prev.map((progress, index) => {
                    if(index===i) return 100;
                }));
            }, i*1500);
        };
    }, []);

    useEffect(() => {
        if(progresses[progresses.length-1]===100) {
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 1300);
        }
    }, [progresses]);

    return (
        <div id='checking-page'>
            <h1>Checking your account</h1>

            <div id="checks">
                <Check heading={"Account Age Verified"} progress={progresses[0]} />
                <Check heading={"Activity Level Analyzed"} progress={progresses[1]} />
                <Check heading={"Telegram Premium Cheked"} progress={progresses[2]} />
                <Check heading={"OG Status Confirmed"} progress={progresses[3]} />
            </div>

            <button
                className="continue-button"
                disabled={isButtonDisabled}
                style={{opacity: isButtonDisabled ? "50%" : "100%"}}
                onClick={() => setPage("onboardingCongrats")}
            >Continue</button>
        </div>
    );
};

export default CheckPage;