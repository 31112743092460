import React, { useEffect, useState } from "react";

import CheeseVector from "../../vectors/Cheese";

function Years({ years }) {
    const [ number, setNumber ] = useState(0);

    // Make increasing effect
    useEffect(() => {
        const interval = setInterval(() => {
            setNumber(prev => {
                if(prev===years) {
                    clearInterval(interval);
                    return years;
                } else {
                    return prev+1;
                }
            });
        }, 300);

        return () => clearInterval(interval);
    }, [years]);

    return (
        <div id="years-container">
            <h1>
                Elite member!
            </h1>
            <h3>
                You’ve joined Telegram
            </h3>

            { CheeseVector }

            <div id="years">
                <h1>{number}</h1>
                <h2>years ago</h2>
            </div>
        </div>
    );
};

export default Years;