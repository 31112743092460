import React, { useEffect, useState } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";

import MouseVector from "../vectors/Mouse";
import TickVector from "../vectors/Tick";
import WalletVector from "../vectors/Wallet";

import { usePageContext } from "../contexts/PageContext";
import { useSocket } from "../contexts/SocketContext";

import Balance from "../components/HomePage/Balance";

function HomePage() {
    const { setPage } = usePageContext();
    const socket = useSocket();

    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [walletAccount, setWalletAccount] = useState(null);

    const [ staticTasksStatuses, setStaticTasksStatuses ] = useState("start");

    useEffect(() => {
        setStaticTasksStatuses(JSON.parse(localStorage.getItem("staticTasksStatuses")));
        setIsWalletConnected(tonConnectUI.connected);
        setWalletAccount(tonConnectUI.account?.address);

        socket.on("tasks", (data) => {
            socket.emit("getPoints");
        });
        socket.on("specialTasks", (data) => {
            socket.emit("getPoints");
        });

        socket.on("staticTasksStatuses", (data) => {
            setStaticTasksStatuses(data);
            socket.emit("getPoints");
        });

        const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
            if (wallet) {
                setIsWalletConnected(true);
                setWalletAccount(wallet.account.address);
            } else {
                setIsWalletConnected(false);
                setWalletAccount(null);
            }
        });

        window.Telegram.WebApp.BackButton.hide();

        window.Telegram.WebApp.headerColor = "#009AF1";
        return () => {
            window.Telegram.WebApp.headerColor = "#000000";
            unsubscribe();
        };
    }, []);


    async function selectWalletAction(e) {
        const option = e.target.value;

        if(option==="disconnect") {
            await tonConnectUI.disconnect();
        };
    };

    async function connectWallet() {
        await tonConnectUI.openModal();
    };

    return (
        <div id="home-page">
            <div className="fade-in-eclipse"></div>
            <div id="squek-container">
                {MouseVector}
                <h1>Your Story</h1>

                <button onClick={() => setPage("squek")}>Squek</button>
            </div>

            {
                isWalletConnected ?
                <div className="wallet-action-select">
                    {WalletVector}
                    <select onChange={selectWalletAction}>
                        <option value={walletAccount}>
                            {
                                `${walletAccount.split("").splice(0, 5).join("")}...${walletAccount.split("").splice(walletAccount.split("").length-5, walletAccount.split("").length).join("")}`
                            }
                        </option>
                        
                        <option style={{color: "red"}} value="disconnect">Disconnect</option>
                    </select>
                </div> :
                <div
                    onClick={connectWallet} className="connect-wallet-button">
                    {WalletVector}
                    <span>Connect Wallet</span>
                </div>
            }
            

            <Balance />

            <div
                className="check-in-task"
            >
                {TickVector}
                <h1>Daily Chek-in</h1>
                <button 
                    onClick={() => setPage("check-in")}
                    style={{
                        backgroundColor: staticTasksStatuses.checkIn==="active" ? "#009AF1" : "#ffffff",
                        color: staticTasksStatuses.checkIn==="active" ? "#ffffff" : "#000000",
                    }}
                >{staticTasksStatuses.checkIn==="active" ? "Check" : "Checked"}</button>
            </div>

            {/* <div id="tasks-container">
                <div className="subcontainer">
                    <h1>Daily Tasks</h1>
                    
                    <CheckInTask
                        name={"Daily Check-In"}
                        reward={100}
                        status={staticTasksStatuses.checkIn}
                    />

                    <WalletTask
                        name={"Connect TON Wallet"}
                        reward={2000}
                        status={staticTasksStatuses.wallet}
                    />

                    <TransactionTask 
                        name={"Make a TON Transaction"}
                        reward={20000}
                        status={staticTasksStatuses.transaction}
                    />
                   
                </div>
                <div className="subcontainer">
                    <h1>Tasks</h1>

                    { tasks.map(task => 
                        <Task
                            key={task._id}
                            id={task._id}
                            name={task.name}
                            reward={task.reward}
                            link={task.link}
                            status={task.status}
                        />
                    ) }

                    <InvitesTask
                        number={1}
                        name={"Invite 1 friend"}
                        reward={100}
                        progress={friends.length}
                        total={1}
                    />
                    <InvitesTask
                        number={2}
                        name={"Invite 5 friends"}
                        reward={500}
                        progress={friends.length}
                        total={5}
                    />
                    <InvitesTask
                        number={3}
                        name={"Invite 10 friends"}
                        reward={1000}
                        progress={friends.length}
                        total={10}
                    />
                </div>
            </div> */}
        </div>
    );
};

export default HomePage;