import React, { useEffect } from "react";

import MouseVector from "../../vectors/Mouse";

import { usePageContext } from "../../contexts/PageContext";
import { useSocket } from "../../contexts/SocketContext";

function WinningPopup({ win, reward, hidePopup }) {
    const { setPage } = usePageContext();
    const socket = useSocket();

    return (
        <div id="winning-popup" className={win ? "win" : "lose"}>
            {MouseVector}

            {
                win ?
                <>
                    <h3>Squeak! <br /> You did well, here’s your reward!</h3>
                    <h1>{reward} MOUSE</h1>
                </> : 
                <>
                    <h1>Whoops!</h1>
                    <h3>No luck today, come back <br /> tomorrow and try again!</h3>
                </>
            }

            <button onClick={() => { setPage("earn"); socket.emit("isGameAvailable") }}>{win ? "Claim" : "Return"}</button>
        </div>
    );
};

export default WinningPopup;