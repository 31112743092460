import React from "react";

import TickVector from "../../vectors/Tick";
import MouseVector from "../../vectors/Mouse";

function InvitesTask({ name, reward, progress, total }) {
    return (
        <div className="task">
            <div className="info">
                <h2>{name}</h2>
                <h3>+{reward} MOUSE</h3>
            </div>

            <div>
                {
                    progress>=total ?
                    <button className="done" >{MouseVector}</button> :
                    <div className="progress">
                        {progress} <span>/</span> {total}
                    </div>
                }
            </div>
        </div>
    )
};

export default InvitesTask;