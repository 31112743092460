function formatTime(milliseconds) {
    const remainingHours = Math.floor(milliseconds / (1000 * 3600));
    const remainingMinutes = Math.floor(milliseconds / (1000 * 60) - (60*remainingHours));
    const remainingSeconds = Math.floor(milliseconds / (1000) - (((remainingMinutes+(remainingHours*60))*60)));

    return {
        hours: remainingHours,
        minutes: remainingMinutes,
        seconds: remainingSeconds
    }
};

function formatTimeDays(milliseconds) {
    const remainingDays = Math.floor(milliseconds / (1000 * 3600 * 24));
    const remainingHours = Math.floor(milliseconds / (1000 * 3600) - (24*remainingDays));
    const remainingMinutes = Math.floor(milliseconds / (1000 * 60) - (((remainingHours+(24*remainingDays))*60)));

    return {
        days: remainingDays,
        hours: remainingHours,
        minutes: remainingMinutes
    }
};

function dateAddMinutes(date, minutes) {
    date.setMinutes(date.getMinutes() + minutes);
    return date;
};

export {
    dateAddMinutes,
    formatTime,
    formatTimeDays
};