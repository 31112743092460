import './styles.css';

import { useEffect, useState } from 'react';

import CheckPage from './pages/CheckPage';
import CongratsPage from './pages/CongratsPage';
import HomePage from './pages/HomePage';
import LeaderboardPage from './pages/LeaderboardPage';
import FriendsPage from './pages/FriendsPage';
import LoadingPage from './pages/LoadingPage';
import SquekPage from './pages/SquekPage';
import EarnPage from './pages/EarnPage';
import GamePage from './pages/GamePage';
import Menu from './components/Menu';

import { useSocket } from './contexts/SocketContext';
import { PageContext } from "./contexts/PageContext";
import CheckInPage from './pages/CheckInPage';

function App() {
	const socket = useSocket();

	const [ page, setPage ] = useState("loading"); // Current page state

	useEffect(() => {
		// Start onboarding if the user is not exist
		// (checking on the server when the socket connects)
		socket.on("startOnboarding", (data) => {
			setPage("onboardingCheck");
		});

		window.Telegram.WebApp.expand();
		window.Telegram.WebApp.ready();
		window.Telegram.WebApp.headerColor = "#000000";
        window.Telegram.WebApp.setBackgroundColor("#000000");
	}, []);

	return (
		<div id='App'>
			<PageContext.Provider value={{ page, setPage }} >
				{ page==="loading" && <LoadingPage /> }

				{ page==="onboardingCheck" && <CheckPage /> }
				{ page==="onboardingCongrats" && <CongratsPage /> }

				{ page==="home" && <HomePage /> }
				{ page==="squek" && <SquekPage /> }
				{ page==="leaderboard" && <LeaderboardPage /> }
				{ page==="friends" && <FriendsPage /> }
				{ page==="earn" && <EarnPage /> }
				{ page==="game" && <GamePage /> }
				{ page==="check-in" && <CheckInPage /> }

				{ (
					page!=="onboardingCheck" &&
					page!=="onboardingCongrats" &&
					page!=="loading" &&
					page!=="squek" &&
					page!=="game" &&
					page!=="check-in"
				) && <Menu /> }
			</PageContext.Provider>
		</div>
	);
};

export default App;
