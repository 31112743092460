import React, { useEffect, useState } from "react";

import Stories from "../components/Stories";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function SquekPage() {
    const { setPage } = usePageContext();

    const socket = useSocket();

    const [stories, setStories] = useState([]);

    useEffect(() => {
        socket.emit("getStories");

        socket.on("stories", (data) => {
            if(data.stories.length === 0) {
                return setPage("onboardingCongrats");
            }

            setStories(data.stories);
        });
    });

    return (
        <div id="squek-page">
            <div className="fade-in-eclipse"></div>

            {
                stories.length>0 &&
                <Stories pages={stories.map(story => <img src={process.env.REACT_APP_SERVER_URL+"/images/"+story.image} alt="" />)} returnPage={"home"} />
            }
        </div>
    )
};

export default SquekPage;