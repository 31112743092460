import React, { useEffect, useRef, useState } from "react";

import MiceQueueVector from "../vectors/MiceQueue";
import MouseVector from "../vectors/Mouse";

import Friend from "../components/FriendsPage/Friend";

import getRandomProfileImage from "../utils/getRandomProfileImage";

import { useSocket } from "../contexts/SocketContext";

function FriendsPage() {
    const socket = useSocket();

    const [ friends, setFriends ] = useState([]);
    const [ inviteLink, setInviteLink ] = useState("");

    const buttonRef = useRef(null);

    useEffect(() => {
        setFriends(JSON.parse(localStorage.getItem("friends")));
        
        socket.emit("getUser");

        socket.on("friends", (data) => {
            setFriends(data.friends);
        });
        socket.on("user", (data) => {
            setInviteLink(`https://t.me/${process.env.REACT_APP_BOT_USERNAME}/${process.env.REACT_APP_APP_NAME}?startapp=${data.user.telegramId}`);
        });
    }, []);

    function copyInviteLink() {
        navigator.clipboard.writeText(inviteLink);

        buttonRef.current.innerText = "Invite link copied";
        
        setTimeout(() => {
            buttonRef.current.innerText = "Invite frens";
        }, 3000);
    };

    return (
        <div id="friends-page">
            <div className="fade-in-eclipse"></div>
            <h1>Invite frens<br/>and get more<br/>$MOUSE</h1>

            {
                friends.length===0 ? 
                <div className="image-container">
                    {MiceQueueVector}
                </div> :
                <div id="friends-list">
                    {MouseVector}

                    <h3>{friends.length} friends</h3>
                    <div>
                        {friends.map((friend, index) => 
                            <Friend
                                key={index}
                                username={friend.username}
                                reward={parseFloat(Number(friend.reward).toFixed(1))}
                                img={getRandomProfileImage()}
                            />
                        )}                
                    </div>
                </div>
            }
            

            <div></div>
            { friends.length===0 && <h3>Tap on the button to invite your friends</h3> }
            <button 
                onClick={copyInviteLink}
                ref={buttonRef}
                className="continue-button"
            >
                Invite frens
            </button>
        </div>
    );
};

export default FriendsPage;