import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";

import MouseAnimation from "../animations/mouse.json";
import MouseVector from "../vectors/Mouse";

import { useSocket } from "../contexts/SocketContext";
import { usePageContext } from "../contexts/PageContext";

function CheckInPage() {
    const socket = useSocket();
    const { setPage } = usePageContext();

    const [ streak, setStreak ] = useState(0);
    const streakRef = useRef(0);

    const elementRef = useRef(null);

    useEffect(() => {
        socket.emit("checkDailyCheckIn");
        socket.on("checkIn", (data) => {
            streakRef.current = data.streak;

            socket.emit("getPoints");
        });

        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            setPage("home");
        });
    }, []);

    function shareStory() {
        window.Telegram.WebApp.shareToStory(
            process.env.REACT_APP_SERVER_URL+"/checkInStoryImage/"+streak,
            {
                text: `Squeak, Squeak!\nThe reward is waiting for you, follow the link - https://t.me/${process.env.REACT_APP_BOT_USERNAME}/${process.env.REACT_APP_APP_NAME}?startapp=${window.Telegram.WebApp.initDataUnsafe.user.id}`
            }
        );
    };

    // Make increasing effect
    useEffect(() => {
        const interval = setInterval(() => {
            setStreak(prev => {
                if(prev===streakRef.current) {
                    clearInterval(interval);
                    return streakRef.current;
                } else {
                    return prev+1;
                }
            });
        }, 300);

        return () => clearInterval(interval);
    }, [streak]);

    return (
        <div id="check-in-page">
            <div className="fade-in-eclipse"></div>

            <div className="story-group">
                <div ref={elementRef} style={{ backgroundColor: "#000000" }}>
                    <div className="image-container">
                        <Lottie animationData={MouseAnimation} loop={false} />
                    </div>

                    <div className="days">
                        <h1>{streak}</h1>
                        <h2>day check-in</h2>
                    </div>
                </div>
            </div>
            
            <div className="points">
                <h3>Your daily rewards</h3>
                <div>
                    {MouseVector}
                    <div>
                        <h1>100</h1>
                        <h2>MOUSE</h2>
                    </div>
                </div>
            </div>

            <h4>
                Regular check-ins are your key to success. <br />
                Keep them up to earn consistent bonuses! 🔥
            </h4>

            <button onClick={shareStory}>Share in story</button>
        </div>
    );
};

export default CheckInPage;