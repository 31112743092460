import React from "react";

import MouseVector from "../../vectors/Mouse";

import { useSocket } from "../../contexts/SocketContext";

function SubTask({ name, reward, link, status, id }) {
    const socket = useSocket();

    function startTask() {
        socket.emit("startSubTask", { id: id });
        
        window.open(link, '_blank').focus();
    };

    function checkTask() {
        socket.emit("checkSubTask", { id: id });
    };

    return (
        <div className="sub-task">

            <div className="info">
                <h2>{name}</h2>
                <h3>+ {reward} MOUSE</h3>
            </div>

            <div>
                <button
                    onClick={ status==="active" ? startTask : status==="check" ? checkTask : () => {} }
                    className={ status==="active" ? "start" : status==="check" ? "check" : "done" }
                >
                    { status==="active" ? "Start" : status==="check" ? "Check" : MouseVector }
                </button>
            </div>
        </div>
    );
};

export default SubTask;