import React, { useEffect, useState } from "react";

import Me from "../components/LeaderboardPage/Me";
import User from "../components/LeaderboardPage/User";

import { useSocket } from "../contexts/SocketContext";

import getRandomProfileImage from "../utils/getRandomProfileImage";

function LeaderboardPage() {
    const socket = useSocket();

    const [leaders, setLeaders] = useState([]);
    const [total, setTotal] = useState(0);
    const [me, setMe] = useState(undefined);

    useEffect(() => {
        const leadersData = JSON.parse(localStorage.getItem("leadersData"));
        setLeaders(leadersData.leaders);
        setMe(leadersData.me);
        setTotal(leadersData.total);

        socket.on("leaders", (data) => {
            setLeaders(data.leaders);
            setMe(data.me);
            setTotal(data.total);
        });
    }, []);

    return (
        <div id="leaderboard-page">
            <div className="fade-in-eclipse"></div>
            <h1>Telegram Wall of Fame</h1>

            { me && <Me
                img={getRandomProfileImage()}
                position={me.position}
                username={me.username}
                points={me.points}
                tick={me.staticTasks.transaction==="done"}
            /> }
            

            <h2>{total} holders</h2>

            <div id="leaders-container">
                {leaders.map((user, index) =>
                    <User
                        key={user.index}
                        img={getRandomProfileImage()}
                        position={index + 1}
                        username={user.username}
                        points={user.points}
                        tick={user.staticTasks.transaction==="done"}
                    />
                )}
            </div>
        </div>
    );
};

export default LeaderboardPage;